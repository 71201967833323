import React, { Component } from "react";
import { Route, NavLink, BrowserRouter, Switch } from "react-router-dom";
import App from "../../App";
import NewRide from "../NewRide";
// import "./Nav.scss";
import Footer from "../Footer";
import logo from '../../assets/chainylogo.png';
import NotFound from '../404';
import styled from "styled-components";

const Navbar = styled.section`
  background: #083555;

  .logo {
    height: 7vh;
    margin: 0 0 0 1rem;;
  }

  .link a{
    color: white;
    padding: 0 1rem 0 0;
  }
`;

class Nav extends Component {
  render() {
    return (
      <div>
      <BrowserRouter>
        <Navbar className='navbar'>
          <span className="navbar-brand"><NavLink exact to="/"><img className="logo" src={logo} alt="logo"/></NavLink> </span>
          <span className="link"><NavLink to="/newride">Add New Ride</NavLink></span>
              <Switch>
                <Route exact path="/" component={App} />
                <Route path="/newride" component={NewRide} />
                <Route component={NotFound} />
              </Switch>
        </Navbar>
      </BrowserRouter>
      <Footer />
      </div>
    );
  }
}

export default Nav;