import React, { Component } from 'react';
import ReactMapGL,  { Marker } from 'react-map-gl';
import Pin from '../../pin';
import '../../App.css';
import './style.scss';
import firebase from '../../firebase.js';
class NewRide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 53.507447,
        longitude: -1.5,
        zoom: 4.5,
        bearing: 0,
        pitch: 0
      },
      marker: {
        latitude: 52.507447,
        longitude: -1.5
      },
      events: {}
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _updateViewport = viewport => {
    this.setState({viewport});
  };

  _logDragEvent(name, event) {
    this.setState({
      events: {
        ...this.state.events,
        [name]: event.lngLat
      }
    });
  }
  _onViewportChange = viewport => this.setState({viewport});

  _onMarkerDragEnd = event => {
    this._logDragEvent('StartPoint', event);
    this.setState({
      marker: {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
      }
    });
    console.log(this.state.marker.latitude);
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault(); //because we don't want the page refreshing on form submit.
    if (this.state.marker.latitude === 52.507447 && this.state.marker.longitude === -1.5) {
      alert('Drag the marker to the starting point before submitting');
    } else {
    const chaingangsRef = firebase.database().ref('chaingangs'); //creating the collection within our firebase db to store the items. calling the ref method and passing in the destination we would like
    const chaingang = { //grabbing the item the user just typed in
      name: this.state.name,
      distance: this.state.distance,
      lng: this.state.marker.longitude,
      lat: this.state.marker.latitude,
      day: this.state.day,
      time: this.state.time
    }

    chaingangsRef.push(chaingang);
    this.setState({ //can clear the inputs so that additional items can be added
      name: '',
      distance: '',
      longitude: '',
      latitude:'',
      day: '',
      time: ''
    });
    alert("Thank you for your submission. It will be reviewed and added shortly");
  }
  }
  render() {
    const {viewport, marker} = this.state;
    return (
      <div>
      <ReactMapGL
        {...viewport}
        width="100vw"
        height="85vh"
        mapStyle="mapbox://styles/tomford93/cjyyj3o2n0d8x1cjp3dgwbnzb"
        onViewportChange={this._onViewportChange}
        mapboxApiAccessToken='pk.eyJ1IjoidG9tZm9yZDkzIiwiYSI6ImNqdTl5NjRtbDJkYTI0ZWxsNm15czNhYnUifQ.XLcmepfaK8QdYPE3dGlO8Q'
      >
      <Marker
        longitude={marker.longitude}
        latitude={marker.latitude}
        offsetTop={-20}
        offsetLeft={-10}
        draggable
        onDragEnd={this._onMarkerDragEnd}
        >
        <Pin size={20} />
            </Marker>
            <section className='add-item'>
              <form className="submit-form" onSubmit={this.handleSubmit}>
                <div className="form-group col-10">
                  <input className="form-control" type="text" name="name" placeholder="Chaingang name" onChange={this.handleChange} value={this.state.name} autocomplete="off" required/>
                  <div class="form-row">
                    <div class="col">
                      <input className="form-control" type="number" name="distance" placeholder="Miles" onChange={this.handleChange} value={this.state.distance} autocomplete="off" required/>
                    </div>
                    <div class="col">
                      <input className="form-control" type="text" name="time" placeholder="Start time" onChange={this.handleChange} value={this.state.time} autocomplete="off" required/>
                    </div>
                  </div>
                  <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref" name="day" onChange={this.handleChange} value={this.state.day} autocomplete="off" required>
                    <option selected>Choose...</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </select>
                  <input className="form-control" type="hidden" name="longitude" value={this.state.marker.longitude}></input>
                  <input className="form-control" type="hidden" name="latitude" value={this.state.marker.latitude}></input>
                  <input className="form-control" type="text" placeholder="Drag red marker to start" readOnly/>

                  <button className="btn btn-primary form-control">Submit</button>
                </div>
              </form>
          </section>
          </ReactMapGL>
    </div>
  );
}
}
export default NewRide;
