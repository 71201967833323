import React, { PureComponent } from "react";
import "./style.scss";

export default class CityInfo extends PureComponent {
  render() {
    const { info } = this.props;
    const displayName = `${info.name}, ${info.distance}miles, ${info.day}, ${info.time}`;

    return (
      <div className="marker-popup">
        <div className="header">{info.name}</div>
        <div className="popup-content">
          <div className="distance">{`Distance: ${info.distance} miles`}</div>
          {console.log(`${info.distance}`)}
          {`${info.facebook}` !== 'undefined' ? <div className="facebook"><a target="_blank" href={`${info.facebook}`}>Facebook</a></div> : null}
          <div className="when">
            {info.day} {info.time}
          </div>
        </div>
      </div>
    );
  }
}
