import firebase from 'firebase'
const config = {
  apiKey: "AIzaSyC4StqhzLVfQi2yQi-lTgE8ttcQFonGD2o",
  authDomain: "chainy-cb6f7.firebaseapp.com",
  databaseURL: "https://chainy-cb6f7.firebaseio.com",
  projectId: "chainy-cb6f7",
  storageBucket: "chainy-cb6f7.appspot.com",
  messagingSenderId: "766013826330",
  appId: "1:766013826330:web:86c6fae2fa0dbd01"
};
// Initialize Firebase
firebase.initializeApp(config);
export default firebase;
