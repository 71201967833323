import React, { Component } from 'react';
import './App.css';
import ReactMapGL,  {Marker, Popup} from 'react-map-gl';
import firebase from './firebase.js';
import CityPin from './components/CityPin';
import CityInfo from './components/CityInfo';

class App extends Component {
  constructor() {
    super();
    this.state = {
      currentItem: '', //change to chainy name and add other info like distance...etc.
      username: '',
      chaingangs: [],//here is how will track what is inside our firebase db,
      viewport: {
        latitude: 53.507447,
        longitude: -1.5,
        zoom: 4.5,
        bearing: 0,
        pitch: 0
      },
      popupInfo: null
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() { //start tracking the items in the db as soon as the component loads on the page
    const chaingangsRef = firebase.database().ref('approved-chaingangs');
    chaingangsRef.on('value', (snapshot) => {
      let chaingangs = snapshot.val();
      let newState = [];
      for (let chaingang in chaingangs) {
        newState.push({
          id: chaingang,
          name: chaingangs[chaingang].name,
          latitude: chaingangs[chaingang].lat,
          facebook: chaingangs[chaingang].facebook,
          longitude: chaingangs[chaingang].lng,
          distance: chaingangs[chaingang].distance,
          time: chaingangs[chaingang].time,
          day: chaingangs[chaingang].day
        });
      }
      this.setState({
        chaingangs: newState
      });
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault(); //because we don't want the page refreshing on form submit.
    const chaingangsRef = firebase.database().ref('chaingangs'); //creating the collection within our firebase db to store the items. calling the ref method and passing in the destination we would like
    const chaingang = { //grabbing the item the user just typed in
      title: this.state.currentItem,
      user: this.state.username
    }
    chaingangsRef.push(chaingang);
    this.setState({ //can clear the inputs so that additional items can be added
      currentItem: '',
      username: ''
    });
  }
  _onViewportChange = viewport => this.setState({viewport});
  _updateViewport = viewport => {
    this.setState({viewport});
  };
  _renderCityMarker = (chaingang, index) => {
    return (
      <Marker key={`marker-${index}`} longitude={chaingang.longitude} latitude={chaingang.latitude}>
        <CityPin size={20} onClick={() => this.setState({popupInfo: chaingang})} />
      </Marker>
    );
  };

  _renderPopup() {
    const {popupInfo} = this.state;

    return (
      popupInfo && (
        <Popup 
          className="popup"
          tipSize={5}
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={() => this.setState({popupInfo: null})}
        >
          <CityInfo info={popupInfo} />
        </Popup>
      )
    );
  }

  _onStyleChange = mapStyle => this.setState({mapStyle});
  render() {
    const {viewport} = this.state;
    return (
      <div className='app'>
        <ReactMapGL
          {...viewport}
            width="100vw"
            height="85vh"
            mapStyle="mapbox://styles/tomford93/cjyyj3o2n0d8x1cjp3dgwbnzb"
            onViewportChange={this._onViewportChange}
            mapboxApiAccessToken='pk.eyJ1IjoidG9tZm9yZDkzIiwiYSI6ImNqdTl5NjRtbDJkYTI0ZWxsNm15czNhYnUifQ.XLcmepfaK8QdYPE3dGlO8Q'
            >
            {this.state.chaingangs.map(this._renderCityMarker)}
            {this._renderPopup()}
          </ReactMapGL>
      </div>
    );
  }
}
export default App;